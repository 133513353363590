import React, { Component } from 'react';
import {
  IssueCarousel,
  Issue,
  Loading
} from '../Components/components';
import Actions from '../Actions.js';
import Store from '../Store.js';

class Home extends Component {
  constructor(props) {
    super(props);
    this.state = {
      issue: {},
      issues: [],
      featuredArticles:[],
      fte: {},
      regularArticles:[],
      loading: true,
    }
  }

  async componentDidMount() {
    this.setState({ loading: true });

    const [ recentIssues, mostRecentIssue ] = await Promise.all([
      Store.receive(Actions.GET_PUBLISHED_ISSUES, {limit: 8, offset: 0}),
      Store.receive(Actions.GET_MOST_RECENT_ISSUE),
    ]);
    this.setState({
      issues: recentIssues,
      issue: mostRecentIssue,
    });
    const issueArticles = await Store.receive(Actions.GET_ARTICLES_FROM_ISSUE, {issueId : this.state.issue.id});
    this.setState({
      fte: issueArticles.fte,
      featuredArticles: issueArticles.featuredArticles,
      regularArticles: issueArticles.regularArticles
    })

    this.setState({ loading: false });
  }

  filterArticlesBySection(articles) {
    const sections = {};
    articles.forEach((article) => {
      const section = article.category;
      if (section in sections) {
        sections[section].push(article)
      } else {
        sections[section] = [ article ];
      }
    })
    this.setState({
      sectionArticles: sections
    })
  }
  
  render() {
    if (this.state.loading) {
      return (
        <div>
          <Loading/>
        </div>
      )
    }
    return (
      <div id={"home-page"}>
        <IssueCarousel loading={this.state.loading}
                       issues={this.state.issues}/>
        <Issue loading={this.state.loading}
               issue={this.state.issue}
               fte={this.state.fte}
               featuredArticles={this.state.featuredArticles}
               regularArticles={this.state.regularArticles} />
      </div>

    )
  }
}

export default Home;
